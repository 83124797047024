:root {
  --rv-button-mini-height: 0.64rem;
  --rv-button-mini-padding: 0 var(--rv-padding-base);
  --rv-button-mini-font-size: var(--rv-font-size-xs);
  --rv-button-small-height: 0.853333rem;
  --rv-button-small-padding: 0 var(--rv-padding-sm);
  --rv-button-small-font-size: var(--rv-font-size-sm);
  --rv-button-normal-padding: 0 0.4rem;
  --rv-button-normal-font-size: var(--rv-font-size-md);
  --rv-button-large-height: 1.333333rem;
  --rv-button-default-height: 1.173333rem;
  --rv-button-default-line-height: 1.2;
  --rv-button-default-font-size: var(--rv-font-size-lg);
  --rv-button-default-color: var(--rv-text-color);
  --rv-button-default-background-color: var(--rv-white);
  --rv-button-default-border-color: var(--rv-border-color);
  --rv-button-primary-color: var(--rv-white);
  --rv-button-primary-background-color: var(--rv-primary-color);
  --rv-button-primary-border-color: var(--rv-primary-color);
  --rv-button-success-color: var(--rv-white);
  --rv-button-success-background-color: var(--rv-success-color);
  --rv-button-success-border-color: var(--rv-success-color);
  --rv-button-danger-color: var(--rv-white);
  --rv-button-danger-background-color: var(--rv-danger-color);
  --rv-button-danger-border-color: var(--rv-danger-color);
  --rv-button-warning-color: var(--rv-white);
  --rv-button-warning-background-color: var(--rv-warning-color);
  --rv-button-warning-border-color: var(--rv-warning-color);
  --rv-button-border-width: var(--rv-border-width-base);
  --rv-button-border-radius: var(--rv-border-radius-sm);
  --rv-button-round-border-radius: var(--rv-border-radius-max);
  --rv-button-plain-background-color: var(--rv-white);
  --rv-button-disabled-opacity: var(--rv-disabled-opacity);
  --rv-button-icon-size: 1.2em;
  --rv-button-loading-icon-size: 0.533333rem;
}

.rv-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 1.173333rem;
  height: var(--rv-button-default-height);
  margin: 0;
  padding: 0;
  font-size: var(--rv-font-size-lg);
  font-size: var(--rv-button-default-font-size);
  line-height: 1.2;
  line-height: var(--rv-button-default-line-height);
  text-align: center;
  border-radius: var(--rv-border-radius-sm);
  border-radius: var(--rv-button-border-radius);
  cursor: pointer;
  -webkit-transition: opacity, box-shadow var(--rv-animation-duration-fast);
  transition: opacity, box-shadow var(--rv-animation-duration-fast);
  -webkit-appearance: none;
}

.rv-button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: var(--rv-black);
  border: inherit;
  border-color: var(--rv-black);
  border-radius: inherit;
  /* inherit parent's border radius */
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  content: ' ';
}

.rv-button:active::before {
  opacity: 0.1;
}

.rv-button--loading::before,
.rv-button--disabled::before {
  display: none;
}

.rv-button--default {
  color: var(--rv-text-color);
  color: var(--rv-button-default-color);
  background: var(--rv-white);
  background: var(--rv-button-default-background-color);
  border: var(--rv-border-width-base) solid var(--rv-border-color);
  border: var(--rv-button-border-width) solid var(--rv-button-default-border-color);
}

.rv-button--primary {
  color: var(--rv-white);
  color: var(--rv-button-primary-color);
  background: var(--rv-primary-color);
  background: var(--rv-button-primary-background-color);
  border: var(--rv-border-width-base) solid var(--rv-primary-color);
  border: var(--rv-button-border-width) solid var(--rv-button-primary-border-color);
}

.rv-button--success,
.rv-button--info {
  color: var(--rv-white);
  color: var(--rv-button-success-color);
  background: var(--rv-success-color);
  background: var(--rv-button-success-background-color);
  border: var(--rv-border-width-base) solid var(--rv-success-color);
  border: var(--rv-button-border-width) solid var(--rv-button-success-border-color);
}

.rv-button--danger {
  color: var(--rv-white);
  color: var(--rv-button-danger-color);
  background: var(--rv-danger-color);
  background: var(--rv-button-danger-background-color);
  border: var(--rv-border-width-base) solid var(--rv-danger-color);
  border: var(--rv-button-border-width) solid var(--rv-button-danger-border-color);
}

.rv-button--warning {
  color: var(--rv-white);
  color: var(--rv-button-warning-color);
  background: var(--rv-warning-color);
  background: var(--rv-button-warning-background-color);
  border: var(--rv-border-width-base) solid var(--rv-warning-color);
  border: var(--rv-button-border-width) solid var(--rv-button-warning-border-color);
}

.rv-button--plain {
  background: var(--rv-white);
  background: var(--rv-button-plain-background-color);
}

.rv-button--plain.rv-button--primary {
  color: var(--rv-primary-color);
  color: var(--rv-button-primary-background-color);
}

.rv-button--plain.rv-button--success,
.rv-button--plain.rv-button--info {
  color: var(--rv-success-color);
  color: var(--rv-button-success-background-color);
}

.rv-button--plain.rv-button--danger {
  color: var(--rv-danger-color);
  color: var(--rv-button-danger-background-color);
}

.rv-button--plain.rv-button--warning {
  color: var(--rv-warning-color);
  color: var(--rv-button-warning-background-color);
}

.rv-button--large {
  width: 100%;
  height: 1.333333rem;
  height: var(--rv-button-large-height);
}

.rv-button--normal {
  padding: 0 0.4rem;
  padding: var(--rv-button-normal-padding);
  font-size: var(--rv-font-size-md);
  font-size: var(--rv-button-normal-font-size);
}

.rv-button--small {
  height: 0.853333rem;
  height: var(--rv-button-small-height);
  padding: 0 var(--rv-padding-sm);
  padding: var(--rv-button-small-padding);
  font-size: var(--rv-font-size-sm);
  font-size: var(--rv-button-small-font-size);
}

.rv-button__loading {
  color: inherit;
  font-size: inherit;
}

.rv-button__loading .rv-loading__spinner {
  width: 0.533333rem;
  width: var(--rv-button-loading-icon-size);
  height: 0.533333rem;
  height: var(--rv-button-loading-icon-size);
  color: currentColor;
}

.rv-button--mini {
  height: 0.64rem;
  height: var(--rv-button-mini-height);
  padding: 0 var(--rv-padding-base);
  padding: var(--rv-button-mini-padding);
  font-size: var(--rv-font-size-xs);
  font-size: var(--rv-button-mini-font-size);
}

.rv-button--mini + .rv-button--mini {
  margin-left: var(--rv-padding-base);
}

.rv-button--block {
  display: block;
  width: 100%;
}

.rv-button--disabled {
  cursor: not-allowed;
  opacity: var(--rv-disabled-opacity);
  opacity: var(--rv-button-disabled-opacity);
}

.rv-button--loading {
  cursor: default;
}

.rv-button--round {
  border-radius: var(--rv-border-radius-max);
  border-radius: var(--rv-button-round-border-radius);
}

.rv-button--square {
  border-radius: 0;
}

.rv-button__content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}

.rv-button__content::before {
  content: ' ';
}

.rv-button__text {
  max-width: 100%;
}

.rv-button__icon {
  font-size: 1.2em;
  font-size: var(--rv-button-icon-size);
  line-height: inherit;
}

.rv-button__icon + .rv-button__text,
.rv-button__loading + .rv-button__text,
.rv-button__text + .rv-button__icon,
.rv-button__text + .rv-button__loading {
  margin-left: var(--rv-padding-base);
}

.rv-button--hairline {
  border-width: 0;
}

.rv-button--hairline::after {
  border-color: inherit;
  border-radius: calc(var(--rv-border-radius-sm) * 2);
  border-radius: calc(var(--rv-button-border-radius) * 2);
}

.rv-button--hairline.rv-button--round::after {
  border-radius: var(--rv-border-radius-max);
  border-radius: var(--rv-button-round-border-radius);
}

.rv-button--hairline.rv-button--square::after {
  border-radius: 0;
}

.rv-button-group {
  display: -webkit-inline-flex;
  display: inline-flex;
  overflow: hidden;
  border: var(--rv-border-width-base) solid var(--rv-border-color);
  border: var(--rv-button-border-width) solid var(--rv-button-default-border-color);
  border-radius: var(--rv-border-radius-sm);
  border-radius: var(--rv-button-border-radius);
}

.rv-button-group .rv-button {
  border-radius: 0;
  -webkit-flex: 1 1;
  flex: 1 1;
}

.rv-button-group .rv-button__text {
  white-space: nowrap;
}

.rv-button-group .rv-button {
  border-width: 0 var(--rv-border-width-base) 0 0;
  border-width: 0 var(--rv-button-border-width) 0 0;
}

.rv-button-group .rv-button:last-child {
  border-width: 0;
}

.rv-button-group--primary {
  border-color: var(--rv-primary-color);
  border-color: var(--rv-button-primary-border-color);
}

.rv-button-group--danger {
  border-color: var(--rv-danger-color);
  border-color: var(--rv-button-danger-border-color);
}

.rv-button-group--warning {
  border-color: var(--rv-warning-color);
  border-color: var(--rv-button-warning-border-color);
}

.rv-button-group--success,
.rv-button-group--info {
  border-color: var(--rv-success-color);
  border-color: var(--rv-button-success-border-color);
}

.rv-button-group--round {
  border-radius: var(--rv-border-radius-max);
  border-radius: var(--rv-button-round-border-radius);
}

.rv-button-group--square {
  border-radius: 0;
}

.rv-button-group--disabled {
  cursor: not-allowed;
  opacity: var(--rv-disabled-opacity);
  opacity: var(--rv-button-disabled-opacity);
}

.rv-button-group--disabled .rv-button {
  --rv-button-disabled-opacity: 1;
}