:root {
  --rv-overlay-z-index: 1;
  --rv-overlay-background-color: rgba(0, 0, 0, 0.7);
}

.rv-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  z-index: var(--rv-overlay-z-index);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--rv-overlay-background-color);
}