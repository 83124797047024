:root {
  --rv-loading-text-color: var(--rv-gray-6);
  --rv-loading-text-font-size: var(--rv-font-size-md);
  --rv-loading-spinner-color: var(--rv-gray-5);
  --rv-loading-spinner-size: 0.8rem;
  --rv-loading-spinner-animation-duration: 0.8s;
}

.rv-loading {
  position: relative;
  color: var(--rv-gray-5);
  font-size: 0;
  vertical-align: middle;
}

.rv-loading__spinner {
  position: relative;
  display: inline-block;
  width: 0.8rem;
  width: var(--rv-loading-spinner-size);
  max-width: 100%;
  height: 0.8rem;
  height: var(--rv-loading-spinner-size);
  max-height: 100%;
  vertical-align: middle;
  -webkit-animation: rv-rotate 0.8s linear infinite;
  animation: rv-rotate 0.8s linear infinite;
  -webkit-animation: rv-rotate var(--rv-loading-spinner-animation-duration) linear infinite;
  animation: rv-rotate var(--rv-loading-spinner-animation-duration) linear infinite;
}

.rv-loading__spinner--spinner {
  -webkit-animation-timing-function: steps(12);
  animation-timing-function: steps(12);
}

.rv-loading__spinner--circular {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.rv-loading__spinner--ball {
  -webkit-animation: none;
  animation: none;
}

.rv-loading__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rv-loading__line::before {
  display: block;
  width: 0.053333rem;
  height: 25%;
  margin: 0 auto;
  background-color: currentColor;
  border-radius: 40%;
  content: ' ';
}

.rv-loading__circular {
  display: block;
  width: 100%;
  height: 100%;
}

.rv-loading__circular circle {
  -webkit-animation: rv-circular 1.5s ease-in-out infinite;
  animation: rv-circular 1.5s ease-in-out infinite;
  stroke: currentColor;
  stroke-width: 3;
  stroke-linecap: round;
}

.rv-loading__text {
  display: inline-block;
  margin-left: var(--rv-padding-xs);
  color: var(--rv-gray-6);
  color: var(--rv-loading-text-color);
  font-size: var(--rv-font-size-md);
  font-size: var(--rv-loading-text-font-size);
  vertical-align: middle;
}

.rv-loading--vertical {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.rv-loading--vertical .rv-loading__text {
  margin: var(--rv-padding-xs) 0 0;
}

.rv-loading__ball {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  /* see note above */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rv-loading__ball div {
  position: absolute;
  box-sizing: content-box;
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
}

.rv-loading__ball div:nth-child(1) {
  background: #448aff;
  -webkit-animation: rv-ball--right 1s linear infinite;
  animation: rv-ball--right 1s linear infinite;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.rv-loading__ball div:nth-child(2) {
  background: #ef5350;
  -webkit-animation: rv-ball--right 1s linear infinite;
  animation: rv-ball--right 1s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.rv-loading__ball div:nth-child(3) {
  background: #448aff;
  -webkit-animation: rv-ball 1s linear infinite;
  animation: rv-ball 1s linear infinite;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes rv-ball {
  0% {
    -webkit-transform: translate(0 0);
    transform: translate(0 0);
    opacity: 1;
  }

  49.99% {
    -webkit-transform: translate(0.64rem, 0);
    transform: translate(0.64rem, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate(0.64rem, 0);
    transform: translate(0.64rem, 0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes rv-ball {
  0% {
    -webkit-transform: translate(0 0);
    transform: translate(0 0);
    opacity: 1;
  }

  49.99% {
    -webkit-transform: translate(0.64rem, 0);
    transform: translate(0.64rem, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate(0.64rem, 0);
    transform: translate(0.64rem, 0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
}

@-webkit-keyframes rv-ball--right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0.64rem, 0);
    transform: translate(0.64rem, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes rv-ball--right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0.64rem, 0);
    transform: translate(0.64rem, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

/* generated by https://loading.io/ */

@-webkit-keyframes rv-circular {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120;
  }
}

@keyframes rv-circular {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120;
  }
}

.rv-loading__spinner--spinner i:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  opacity: 1;
}

.rv-loading__spinner--spinner i:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  opacity: 0.9375;
}

.rv-loading__spinner--spinner i:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 0.875;
}

.rv-loading__spinner--spinner i:nth-of-type(4) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  opacity: 0.8125;
}

.rv-loading__spinner--spinner i:nth-of-type(5) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  opacity: 0.75;
}

.rv-loading__spinner--spinner i:nth-of-type(6) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  opacity: 0.6875;
}

.rv-loading__spinner--spinner i:nth-of-type(7) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  opacity: 0.625;
}

.rv-loading__spinner--spinner i:nth-of-type(8) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  opacity: 0.5625;
}

.rv-loading__spinner--spinner i:nth-of-type(9) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  opacity: 0.5;
}

.rv-loading__spinner--spinner i:nth-of-type(10) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  opacity: 0.4375;
}

.rv-loading__spinner--spinner i:nth-of-type(11) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  opacity: 0.375;
}

.rv-loading__spinner--spinner i:nth-of-type(12) {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: 0.3125;
}