:root {
  --rv-toast-max-width: 70%;
  --rv-toast-font-size: var(--rv-font-size-md);
  --rv-toast-text-color: var(--rv-white);
  --rv-toast-loading-icon-color: var(--rv-white);
  --rv-toast-line-height: var(--rv-line-height-md);
  --rv-toast-border-radius: var(--rv-border-radius-lg);
  --rv-toast-background-color: rgba(0, 0, 0, 0.7);
  --rv-toast-icon-size: 0.96rem;
  --rv-toast-text-min-width: 2.56rem;
  --rv-toast-text-padding: var(--rv-padding-xs) var(--rv-padding-sm);
  --rv-toast-default-padding: var(--rv-padding-md);
  --rv-toast-default-width: 2.346667rem;
  --rv-toast-default-min-height: 2.346667rem;
  --rv-toast-position-top-distance: 20%;
  --rv-toast-position-bottom-distance: 20%;
}

.rv-toast.rv-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 2.346667rem;
  width: var(--rv-toast-default-width);
  max-width: 70%;
  max-width: var(--rv-toast-max-width);
  min-height: 2.346667rem;
  min-height: var(--rv-toast-default-min-height);
  padding: var(--rv-padding-md);
  padding: var(--rv-toast-default-padding);
  color: var(--rv-white);
  color: var(--rv-toast-text-color);
  font-size: var(--rv-font-size-md);
  font-size: var(--rv-toast-font-size);
  line-height: var(--rv-line-height-md);
  line-height: var(--rv-toast-line-height);
  white-space: pre-wrap;
  text-align: center;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--rv-toast-background-color);
  border-radius: var(--rv-border-radius-lg);
  border-radius: var(--rv-toast-border-radius);
  -webkit-transition: all var(--rv-animation-duration-fast);
  transition: all var(--rv-animation-duration-fast);
}

.rv-toast.rv-toast--unclickable {
  overflow: hidden;
  cursor: not-allowed;
}

.rv-toast.rv-toast--unclickable * {
  pointer-events: none;
}

.rv-toast.rv-toast--info,
.rv-toast.rv-toast--html {
  width: -webkit-fit-content;
  width: fit-content;
  min-width: 2.56rem;
  min-width: var(--rv-toast-text-min-width);
  min-height: 0;
  padding: var(--rv-padding-xs) var(--rv-padding-sm);
  padding: var(--rv-toast-text-padding);
}

.rv-toast.rv-toast--info .rv-toast__info,
.rv-toast.rv-toast--html .rv-toast__info {
  margin-top: 0;
}

.rv-toast.rv-toast--top {
  top: 20%;
  top: var(--rv-toast-position-top-distance);
}

.rv-toast.rv-toast--bottom {
  top: auto;
  bottom: 20%;
  bottom: var(--rv-toast-position-bottom-distance);
}

.rv-toast__icon {
  font-size: 0.96rem;
  font-size: var(--rv-toast-icon-size);
}

.rv-toast__loading {
  padding: var(--rv-padding-base);
  color: var(--rv-white);
  color: var(--rv-toast-loading-icon-color);
}

.rv-toast__info {
  margin-top: var(--rv-padding-xs);
}