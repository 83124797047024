:root {
  --rv-popup-background-color: var(--rv-white);
  --rv-popup-transition: transform var(--rv-animation-duration-base);
  --rv-popup-round-border-radius: 0.426667rem;
  --rv-popup-close-icon-size: 0.586667rem;
  --rv-popup-close-icon-color: var(--rv-gray-5);
  --rv-popup-close-icon-active-color: var(--rv-gray-6);
  --rv-popup-close-icon-margin: 0.426667rem;
  --rv-popup-close-icon-z-index: 1;
  --rv-popup-title-color: 'inherit';
  --rv-popup-title-font-size: 0.426667rem;
  --rv-popup-description-font-size: 0.373333rem;
  --rv-popup-description-color: #969799;
}

.rv-overflow-hidden {
  overflow: hidden !important;
}

.rv-popup {
  position: fixed;
  max-height: 100%;
  overflow-y: auto;
  background-color: var(--rv-white);
  background-color: var(--rv-popup-background-color);
  -webkit-transition: -webkit-transform var(--rv-animation-duration-base);
  transition: -webkit-transform var(--rv-animation-duration-base);
  transition: transform var(--rv-animation-duration-base);
  transition: transform var(--rv-animation-duration-base), -webkit-transform var(--rv-animation-duration-base);
  -webkit-transition: var(--rv-popup-transition);
  transition: var(--rv-popup-transition);
  -webkit-overflow-scrolling: touch;
}

.rv-popup--center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.rv-popup--center.rv-popup--round {
  border-radius: 0.426667rem;
  border-radius: var(--rv-popup-round-border-radius);
}

.rv-popup--top {
  top: 0;
  left: 0;
  width: 100%;
}

.rv-popup--top.rv-popup--round {
  border-radius: 0 0 0.426667rem 0.426667rem;
  border-radius: 0 0 var(--rv-popup-round-border-radius) var(--rv-popup-round-border-radius);
}

.rv-popup--right {
  top: 0;
  right: 0;
}

.rv-popup--right.rv-popup--round {
  border-radius: 0.426667rem 0 0 0.426667rem;
  border-radius: var(--rv-popup-round-border-radius) 0 0 var(--rv-popup-round-border-radius);
}

.rv-popup--bottom {
  bottom: 0;
  left: 0;
  width: 100%;
}

.rv-popup--bottom.rv-popup--round {
  border-radius: 0.426667rem 0.426667rem 0 0;
  border-radius: var(--rv-popup-round-border-radius) var(--rv-popup-round-border-radius) 0 0;
}

.rv-popup--left {
  top: 0;
  left: 0;
}

.rv-popup--left.rv-popup--round {
  border-radius: 0 0.426667rem 0.426667rem 0;
  border-radius: 0 var(--rv-popup-round-border-radius) var(--rv-popup-round-border-radius) 0;
}

.rv-popup-slide-top-enter,
.rv-popup-slide-top-exit-active {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.rv-popup-slide-right-enter,
.rv-popup-slide-right-exit-active {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.rv-popup-slide-bottom-enter,
.rv-popup-slide-bottom-exit-active {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.rv-popup-slide-left-enter,
.rv-popup-slide-left-exit-active {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.rv-popup-slide-top-enter-active,
.rv-popup-slide-left-enter-active,
.rv-popup-slide-right-enter-active,
.rv-popup-slide-bottom-enter-active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform var(--rv-animation-duration-base);
  transition: -webkit-transform var(--rv-animation-duration-base);
  transition: transform var(--rv-animation-duration-base);
  transition: transform var(--rv-animation-duration-base), -webkit-transform var(--rv-animation-duration-base);
  -webkit-transition: var(--rv-popup-transition);
  transition: var(--rv-popup-transition);
}

.rv-popup-slide-top-exit-active,
.rv-popup-slide-left-exit-active,
.rv-popup-slide-right-exit-active,
.rv-popup-slide-bottom-exit-active {
  -webkit-transition: -webkit-transform var(--rv-animation-duration-base);
  transition: -webkit-transform var(--rv-animation-duration-base);
  transition: transform var(--rv-animation-duration-base);
  transition: transform var(--rv-animation-duration-base), -webkit-transform var(--rv-animation-duration-base);
  -webkit-transition: var(--rv-popup-transition);
  transition: var(--rv-popup-transition);
  -webkit-transition-timing-function: var(--rv-animation-timing-function-leave);
  transition-timing-function: var(--rv-animation-timing-function-leave);
}

.rv-popup__close-icon {
  position: absolute;
  z-index: 1;
  z-index: var(--rv-popup-close-icon-z-index);
  color: var(--rv-gray-5);
  color: var(--rv-popup-close-icon-color);
  font-size: 0.586667rem;
  font-size: var(--rv-popup-close-icon-size);
  cursor: pointer;
}

.rv-popup__close-icon:active {
  color: var(--rv-gray-6);
  color: var(--rv-popup-close-icon-active-color);
}

.rv-popup__close-icon--top-left {
  top: 0.426667rem;
  top: var(--rv-popup-close-icon-margin);
  left: 0.426667rem;
  left: var(--rv-popup-close-icon-margin);
}

.rv-popup__close-icon--top-right {
  top: 0.426667rem;
  top: var(--rv-popup-close-icon-margin);
  right: 0.426667rem;
  right: var(--rv-popup-close-icon-margin);
}

.rv-popup__close-icon--bottom-left {
  bottom: 0.426667rem;
  bottom: var(--rv-popup-close-icon-margin);
  left: 0.426667rem;
  left: var(--rv-popup-close-icon-margin);
}

.rv-popup__close-icon--bottom-right {
  right: 0.426667rem;
  right: var(--rv-popup-close-icon-margin);
  bottom: 0.426667rem;
  bottom: var(--rv-popup-close-icon-margin);
}

.rv-popup__title {
  margin: 0.533333rem 0.32rem;
  font-weight: 500;
  color: 'inherit';
  color: var(--rv-popup-title-color);
  font-size: 0.426667rem;
  font-size: var(--rv-popup-title-font-size);
  line-height: 0.426667rem;
  line-height: var(--rv-popup-title-font-size);
  text-align: center;
}

.rv-popup__description {
  margin: 0 0.533333rem;
  color: #969799;
  color: var(--rv-popup-description-color);
  font-size: 0.373333rem;
  font-size: var(--rv-popup-description-font-size);
}