:root {
  --rv-calendar-background-color: var(--rv-white);
  --rv-calendar-popup-height: 80%;
  --rv-calendar-header-box-shadow: 0 0.053333rem 0.266667rem rgba(125, 126, 128, 0.16);
  --rv-calendar-header-title-height: 1.173333rem;
  --rv-calendar-header-title-font-size: var(--rv-font-size-lg);
  --rv-calendar-header-subtitle-font-size: var(--rv-font-size-md);
  --rv-calendar-weekdays-height: 0.8rem;
  --rv-calendar-weekdays-font-size: var(--rv-font-size-sm);
  --rv-calendar-month-title-font-size: var(--rv-font-size-md);
  --rv-calendar-month-mark-color: rgba(242, 243, 245, 0.8);
  --rv-calendar-month-mark-font-size: 4.266667rem;
  --rv-calendar-day-height: 1.706667rem;
  --rv-calendar-day-font-size: var(--rv-font-size-lg);
  --rv-calendar-range-edge-color: var(--rv-white);
  --rv-calendar-range-edge-background-color: var(--rv-danger-color);
  --rv-calendar-range-middle-color: var(--rv-danger-color);
  --rv-calendar-range-middle-background-opacity: 0.1;
  --rv-calendar-selected-day-size: 1.44rem;
  --rv-calendar-selected-day-color: var(--rv-white);
  --rv-calendar-info-font-size: var(--rv-font-size-xs);
  --rv-calendar-info-line-height: var(--rv-line-height-xs);
  --rv-calendar-selected-day-background-color: var(--rv-danger-color);
  --rv-calendar-day-disabled-color: var(--rv-gray-5);
  --rv-calendar-confirm-button-height: 0.96rem;
  --rv-calendar-confirm-button-margin: 0.186667rem 0;
}

.rv-calendar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  background-color: var(--rv-white);
  background-color: var(--rv-calendar-background-color);
}

.rv-calendar__popup.rv-popup--top,
.rv-calendar__popup.rv-popup--bottom {
  height: 80%;
  height: var(--rv-calendar-popup-height);
}

.rv-calendar__popup.rv-popup--left,
.rv-calendar__popup.rv-popup--right {
  height: 100%;
}

.rv-calendar__popup .rv-popup__close-icon {
  top: 0.293333rem;
}

.rv-calendar__header {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  box-shadow: 0 0.053333rem 0.266667rem rgba(125, 126, 128, 0.16);
  box-shadow: var(--rv-calendar-header-box-shadow);
}

.rv-calendar__month-title,
.rv-calendar__header-title,
.rv-calendar__header-subtitle {
  height: 1.173333rem;
  height: var(--rv-calendar-header-title-height);
  font-weight: var(--rv-font-weight-bold);
  line-height: 1.173333rem;
  line-height: var(--rv-calendar-header-title-height);
  text-align: center;
}

.rv-calendar__header-title {
  font-size: var(--rv-font-size-lg);
  font-size: var(--rv-calendar-header-title-font-size);
}

.rv-calendar__header-subtitle {
  font-size: var(--rv-font-size-md);
  font-size: var(--rv-calendar-header-subtitle-font-size);
}

.rv-calendar__month-title {
  font-size: var(--rv-font-size-md);
  font-size: var(--rv-calendar-month-title-font-size);
}

.rv-calendar__weekdays {
  display: -webkit-flex;
  display: flex;
}

.rv-calendar__weekday {
  -webkit-flex: 1 1;
  flex: 1 1;
  font-size: var(--rv-font-size-sm);
  font-size: var(--rv-calendar-weekdays-font-size);
  line-height: 0.8rem;
  line-height: var(--rv-calendar-weekdays-height);
  text-align: center;
}

.rv-calendar__body {
  -webkit-flex: 1 1;
  flex: 1 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.rv-calendar__days {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-user-select: none;
  user-select: none;
}

.rv-calendar__month-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  color: rgba(242, 243, 245, 0.8);
  color: var(--rv-calendar-month-mark-color);
  font-size: 4.266667rem;
  font-size: var(--rv-calendar-month-mark-font-size);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.rv-calendar__day,
.rv-calendar__selected-day {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.rv-calendar__day {
  position: relative;
  width: 14.285%;
  height: 1.706667rem;
  height: var(--rv-calendar-day-height);
  font-size: var(--rv-font-size-lg);
  font-size: var(--rv-calendar-day-font-size);
  cursor: pointer;
}

.rv-calendar__day--end,
.rv-calendar__day--start,
.rv-calendar__day--start-end,
.rv-calendar__day--multiple-middle,
.rv-calendar__day--multiple-selected {
  color: var(--rv-white);
  color: var(--rv-calendar-range-edge-color);
  background-color: var(--rv-danger-color);
  background-color: var(--rv-calendar-range-edge-background-color);
}

.rv-calendar__day--start {
  border-radius: var(--rv-border-radius-md) 0 0 var(--rv-border-radius-md);
}

.rv-calendar__day--end {
  border-radius: 0 var(--rv-border-radius-md) var(--rv-border-radius-md) 0;
}

.rv-calendar__day--start-end,
.rv-calendar__day--multiple-selected {
  border-radius: var(--rv-border-radius-md);
}

.rv-calendar__day--middle {
  color: var(--rv-danger-color);
  color: var(--rv-calendar-range-middle-color);
}

.rv-calendar__day--middle::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  opacity: 0.1;
  opacity: var(--rv-calendar-range-middle-background-opacity);
  content: '';
}

.rv-calendar__day--disabled {
  color: var(--rv-gray-5);
  color: var(--rv-calendar-day-disabled-color);
  cursor: default;
}

.rv-calendar__top-info,
.rv-calendar__bottom-info {
  position: absolute;
  right: 0;
  left: 0;
  font-size: var(--rv-font-size-xs);
  font-size: var(--rv-calendar-info-font-size);
  line-height: var(--rv-line-height-xs);
  line-height: var(--rv-calendar-info-line-height);
}

@media (max-width: 350px) {
  .rv-calendar__top-info,
  .rv-calendar__bottom-info {
    font-size: 0.24rem;
  }
}

.rv-calendar__top-info {
  top: 0.16rem;
}

.rv-calendar__bottom-info {
  bottom: 0.16rem;
}

.rv-calendar__selected-day {
  width: 1.44rem;
  width: var(--rv-calendar-selected-day-size);
  height: 1.44rem;
  height: var(--rv-calendar-selected-day-size);
  color: var(--rv-white);
  color: var(--rv-calendar-selected-day-color);
  background-color: var(--rv-danger-color);
  background-color: var(--rv-calendar-selected-day-background-color);
  border-radius: var(--rv-border-radius-md);
}

.rv-calendar__footer {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  padding-right: var(--rv-padding-md);
  padding-left: var(--rv-padding-md);
}

.rv-calendar__confirm.rv-button {
  height: 0.96rem;
  height: var(--rv-calendar-confirm-button-height);
  margin: 0.186667rem 0;
  margin: var(--rv-calendar-confirm-button-margin);
}