.area-zoom-in-top-enter,
.area-zoom-in-top-exit-active {
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.area-zoom-in-top-enter-active,
.area-zoom-in-top-exit {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

.area-select-wrap .area-select {
  margin-left: 0.266667rem;
}

.area-select-wrap .area-select-empty {
  padding: 0.106667rem 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 0.373333rem;
}

.area-select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none;
  height: 0.853333rem;
  cursor: pointer;
  background: #fff;
  border-radius: 0.106667rem;
  border: 0.026667rem solid #e1e2e6;
}

.area-select * {
  box-sizing: border-box;
}

.area-select:hover {
  border-color: #a1a4ad;
}

.area-select.small {
  width: 3.36rem;
}

.area-select.medium {
  width: 4.266667rem;
}

.area-select.large {
  width: 5.173333rem;
}

.area-select.is-disabled {
  background: #eceff5;
  cursor: not-allowed;
}

.area-select.is-disabled:hover {
  border-color: #e1e2e6;
}

.area-select.is-disabled .area-selected-trigger {
  cursor: not-allowed;
}

.area-select .area-selected-trigger {
  position: relative;
  display: block;
  font-size: 0.373333rem;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  line-height: 100%;
  padding: 0.213333rem 0.533333rem 0.186667rem 0.32rem;
}

.area-select .area-select-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.053333rem;
  right: 0.16rem;
  content: "";
  width: 0;
  height: 0;
  border: 0.16rem solid transparent;
  border-top-color: #a1a4ad;
  transition: all .3s linear;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.area-select .area-select-icon.active {
  margin-top: -0.213333rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.area-selectable-list-wrap {
  position: absolute;
  width: 100%;
  max-height: 7.333333rem;
  z-index: 15000;
  border: 0.026667rem solid #a1a4ad;
  border-radius: 0.053333rem;
  background-color: #fff;
  box-shadow: 0 0.053333rem 0.106667rem rgba(0,0,0,.12),0 0 0.16rem rgba(0,0,0,.04);
  box-sizing: border-box;
  margin: 0.133333rem 0;
  overflow-x: hidden;
  overflow-x: auto;
}

.area-selectable-list {
  position: relative;
  margin: 0;
  padding: 0.16rem 0;
  width: 100%;
  font-size: 0.373333rem;
  color: #565656;
  list-style: none;
}

.area-selectable-list .area-select-option {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0 0.4rem 0 0.266667rem;
  height: 0.853333rem;
  line-height: 0.853333rem;
}

.area-selectable-list .area-select-option.hover {
  background-color: #e4e8f1;
}

.area-selectable-list .area-select-option.selected {
  background-color: #e4e8f1;
  color: #ff6200;
  font-weight: 700;
}

.cascader-menu-list-wrap {
  position: absolute;
  white-space: nowrap;
  z-index: 15000;
  border: 0.026667rem solid #a1a4ad;
  border-radius: 0.053333rem;
  background-color: #fff;
  box-shadow: 0 0.053333rem 0.106667rem rgba(0,0,0,.12),0 0 0.16rem rgba(0,0,0,.04);
  box-sizing: border-box;
  margin: 0.133333rem 0;
  overflow: hidden;
  font-size: 0;
}

.cascader-menu-list {
  position: relative;
  margin: 0;
  font-size: 0.373333rem;
  color: #565656;
  padding: 0.16rem 0;
  list-style: none;
  display: inline-block;
  height: 5.44rem;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 4.266667rem;
  vertical-align: top;
  background-color: #fff;
  border-right: 0.026667rem solid #e4e7ed;
}

.cascader-menu-list:last-child {
  border-right: none;
}

.cascader-menu-list .cascader-menu-option {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0 0.4rem 0 0.266667rem;
  height: 0.853333rem;
  line-height: 0.853333rem;
}

.cascader-menu-list .cascader-menu-option.hover,
.cascader-menu-list .cascader-menu-option:hover {
  background-color: #e4e8f1;
}

.cascader-menu-list .cascader-menu-option.selected {
  background-color: #e4e8f1;
  color: #ff6200;
  font-weight: 700;
}

.cascader-menu-list .cascader-menu-option.cascader-menu-extensible:after {
  position: absolute;
  top: 50%;
  margin-top: -0.106667rem;
  right: 0.133333rem;
  content: "";
  width: 0;
  height: 0;
  border: 0.106667rem solid transparent;
  border-left-color: #a1a4ad;
}

.area-selectable-list-wrap::-webkit-scrollbar,
.cascader-menu-list::-webkit-scrollbar {
  width: 0.213333rem;
  background: transparent;
}

.area-selectable-list-wrap::-webkit-scrollbar-button:vertical:decremen,
.area-selectable-list-wrap::-webkit-scrollbar-button:vertical:end:decrement,
.area-selectable-list-wrap::-webkit-scrollbar-button:vertical:increment,
.area-selectable-list-wrap::-webkit-scrollbar-button:vertical:start:increment,
.cascader-menu-list::-webkit-scrollbar-button:vertical:decremen,
.cascader-menu-list::-webkit-scrollbar-button:vertical:end:decrement,
.cascader-menu-list::-webkit-scrollbar-button:vertical:increment,
.cascader-menu-list::-webkit-scrollbar-button:vertical:start:increment {
  display: none;
}

.area-selectable-list-wrap::-webkit-scrollbar-thumb:vertical,
.cascader-menu-list::-webkit-scrollbar-thumb:vertical {
  background-color: #b8b8b8;
  border-radius: 0.106667rem;
}

.area-selectable-list-wrap::-webkit-scrollbar-thumb:vertical:hover,
.cascader-menu-list::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #777;
}