.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 0.533333rem;
  background: none;
  border: 0;
  font-size: 0.853333rem;
  cursor: pointer;
}

.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 0.133333rem;
  display: inline-block;
  border-top: 0.213333rem solid transparent;
  border-bottom: 0.213333rem solid transparent;
  content: '';
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 0.213333rem solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 0.213333rem solid #fff;
}

.carousel-root {
  outline: none;
}

.carousel {
  position: relative;
  width: 100%;
}

.carousel * {
  box-sizing: border-box;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -0.346667rem;
  font-size: 0.48rem;
}

.carousel .thumbs-wrapper {
  margin: 0.533333rem;
  overflow: hidden;
}

.carousel .thumbs {
  transition: all .15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}

.carousel .thumb {
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 0.16rem;
  white-space: nowrap;
  overflow: hidden;
  border: 0.08rem solid #fff;
  padding: 0.053333rem;
}

.carousel .thumb:focus {
  border: 0.08rem solid #ccc;
  outline: none;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0.08rem solid #333;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 0.693333rem;
  bottom: 0;
  margin-top: 0;
  padding: 0.133333rem;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0,0,0,0.2);
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height .15s ease-in;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}

.carousel .slider.animated {
  transition: all .35s ease-in-out;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}

.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 2.133333rem);
  margin: 0 1.066667rem 1.066667rem;
  border: 0;
}

.carousel .slide .legend {
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 1.066667rem;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 0.266667rem;
  background: #000;
  color: #fff;
  padding: 0.266667rem;
  font-size: 0.32rem;
  text-align: center;
  opacity: 0.25;
  transition: opacity .35s ease-in-out;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 0.266667rem 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}

.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 0.026667rem 0.026667rem 0.053333rem rgba(0,0,0,0.9);
  background: #fff;
  border-radius: 50%;
  width: 0.213333rem;
  height: 0.213333rem;
  cursor: pointer;
  display: inline-block;
  margin: 0 0.213333rem;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.133333rem;
  font-size: 0.266667rem;
  text-shadow: 0.026667rem 0.026667rem 0.026667rem rgba(0,0,0,0.9);
  color: #fff;
}

.carousel:hover .slide .legend {
  opacity: 1;
}