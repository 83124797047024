:root {
  --rv-brand-color: #3f45ff;
  --rv-black: #000;
  --rv-white: #fff;
  --rv-gray-1: #f7f8fa;
  --rv-gray-2: #f2f3f5;
  --rv-gray-3: #ebedf0;
  --rv-gray-4: #dcdee0;
  --rv-gray-5: #c8c9cc;
  --rv-gray-6: #969799;
  --rv-gray-7: #646566;
  --rv-gray-8: #323232;
  --rv-red: #f44336;
  --rv-blue: #3f45ff;
  --rv-orange: #ff976a;
  --rv-orange-dark: #ff590d;
  --rv-orange-light: #fffbe8;
  --rv-green: #00c853;
  --rv-gradient-red: linear-gradient(to right, #ff6034, #ee0a24);
  --rv-gradient-orange: linear-gradient(to right, #ffd01e, #ff8917);
  --rv-primary-color: var(--rv-blue);
  --rv-success-color: var(--rv-green);
  --rv-danger-color: var(--rv-red);
  --rv-warning-color: var(--rv-orange);
  --rv-text-color: var(--rv-gray-8);
  --rv-text-color-weak: #969799;
  --rv-active-color: var(--rv-gray-2);
  --rv-active-opacity: 0.7;
  --rv-disabled-opacity: 0.5;
  --rv-background-color: var(--rv-gray-1);
  --rv-background-color-light: #fafafa;
  --rv-text-link-color: #576b95;
  --rv-padding-base: 0.106667rem;
  --rv-padding-xs: 0.213333rem;
  --rv-padding-sm: 0.32rem;
  --rv-padding-md: 0.426667rem;
  --rv-padding-lg: 0.64rem;
  --rv-padding-xl: 0.853333rem;
  --rv-font-size-xs: 0.266667rem;
  --rv-font-size-sm: 0.32rem;
  --rv-font-size-md: 0.373333rem;
  --rv-font-size-lg: 0.426667rem;
  --rv-font-size-xl: 0.48rem;
  --rv-font-size-xxl: 0.533333rem;
  --rv-font-size-xxxl: 0.64rem;
  --rv-font-weight-bold: 500;
  --rv-line-height-xs: 0.373333rem;
  --rv-line-height-sm: 0.48rem;
  --rv-line-height-md: 0.533333rem;
  --rv-line-height-lg: 0.586667rem;
  --rv-line-height-xl: 0.64rem;
  --rv-line-height-xxl: 0.746667rem;
  --rv-line-height-xxxl: 0.853333rem;
  --rv-base-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --rv-price-integer-font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif;
  --rv-animation-duration-base: 0.3s;
  --rv-animation-duration-fast: 0.2s;
  --rv-animation-timing-function-enter: ease-out;
  --rv-animation-timing-function-leave: ease-in;
  --rv-border-color: var(--rv-gray-3);
  --rv-border-width-base: 0.026667rem;
  --rv-border-radius-sm: 0.053333rem;
  --rv-border-radius-md: 0.106667rem;
  --rv-border-radius-lg: 0.213333rem;
  --rv-border-radius-max: 26.64rem;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}

a {
  text-decoration: none;
}

input,
button,
textarea {
  color: inherit;
  font: inherit;
}

a:focus,
input:focus,
button:focus,
textarea:focus,
[class*='rv-']:focus {
  outline: none;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@-webkit-keyframes rv-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rv-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes rv-slide-up-enter {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes rv-slide-up-enter {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes rv-slide-up-leave {
  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes rv-slide-up-leave {
  to {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@-webkit-keyframes rv-slide-down-enter {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes rv-slide-down-enter {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes rv-slide-down-leave {
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes rv-slide-down-leave {
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes rv-slide-left-enter {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes rv-slide-left-enter {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes rv-slide-left-leave {
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes rv-slide-left-leave {
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes rv-slide-right-enter {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes rv-slide-right-enter {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes rv-slide-right-leave {
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes rv-slide-right-leave {
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes rv-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rv-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes rv-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rv-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes rv-zoom-in {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rv-zoom-in {
  from {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes rv-zoom-out {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes rv-zoom-out {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@-webkit-keyframes rv-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rv-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rv-fade-enter-active {
  -webkit-animation: 0.3s rv-fade-in both ease-out;
  animation: 0.3s rv-fade-in both ease-out;
}

.rv-fade-exit-active {
  -webkit-animation: 0.3s rv-fade-out both ease-in;
  animation: 0.3s rv-fade-out both ease-in;
}

.rv-slide-up-enter-active {
  -webkit-animation: 0.3s rv-slide-up-enter both ease-out;
  animation: 0.3s rv-slide-up-enter both ease-out;
}

.rv-slide-up-exit-active {
  -webkit-animation: 0.3s rv-slide-up-leave both ease-in;
  animation: 0.3s rv-slide-up-leave both ease-in;
}

.rv-slide-down-enter-active {
  -webkit-animation: rv-slide-down-enter 0.3s both ease-out;
  animation: rv-slide-down-enter 0.3s both ease-out;
}

.rv-slide-down-exit-active {
  -webkit-animation: rv-slide-down-leave 0.3s both ease-in;
  animation: rv-slide-down-leave 0.3s both ease-in;
}

.rv-slide-left-enter-active {
  -webkit-animation: rv-slide-left-enter 0.3s both ease-out;
  animation: rv-slide-left-enter 0.3s both ease-out;
}

.rv-slide-left-exit-active {
  -webkit-animation: rv-slide-left-leave 0.3s both ease-in;
  animation: rv-slide-left-leave 0.3s both ease-in;
}

.rv-slide-right-enter-active {
  -webkit-animation: rv-slide-right-enter 0.3s both ease-out;
  animation: rv-slide-right-enter 0.3s both ease-out;
}

.rv-slide-right-exit-active {
  -webkit-animation: rv-slide-right-leave 0.3s both ease-in;
  animation: rv-slide-right-leave 0.3s both ease-in;
}

.rv-zoom-enter-active {
  -webkit-animation: 0.3s rv-zoom-in both ease-out;
  animation: 0.3s rv-zoom-in both ease-out;
}

.rv-zoom-exit-active {
  -webkit-animation: 0.3s rv-zoom-out both ease-in;
  animation: 0.3s rv-zoom-out both ease-in;
}

.rv-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rv-multi-ellipsis--l2 {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.rv-multi-ellipsis--l3 {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.rv-safe-area-top {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.rv-safe-area-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

[class*='rv-hairline']::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  border: 0 solid #ebedf0;
  border: 0 solid var(--rv-gray-3);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.rv-hairline,
.rv-hairline--top,
.rv-hairline--left,
.rv-hairline--right,
.rv-hairline--bottom,
.rv-hairline--surround,
.rv-hairline--top-bottom {
  position: relative;
}

.rv-hairline--top::after {
  border-top-width: 0.026667rem;
}

.rv-hairline--left::after {
  border-left-width: 0.026667rem;
}

.rv-hairline--right::after {
  border-right-width: 0.026667rem;
}

.rv-hairline--bottom::after {
  border-bottom-width: 0.026667rem;
}

.rv-hairline--top-bottom::after,
.rv-hairline-unset--top-bottom::after {
  border-width: 0.026667rem 0;
}

.rv-hairline--surround::after {
  border-width: 0.026667rem;
}

[class*='rv-shadow']:hover,
[class*='rv-shadow']:active {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.053333rem 0.106667rem -0.026667rem, rgba(0, 0, 0, 0.14) 0 0.106667rem 0.133333rem 0, rgba(0, 0, 0, 0.12) 0 0.026667rem 0.266667rem 0;
}

.rv-shadow--1 {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.053333rem 0.026667rem -0.026667rem, rgba(0, 0, 0, 0.14) 0 0.026667rem 0.026667rem 0, rgba(0, 0, 0, 0.12) 0 0.026667rem 0.08rem 0;
}

.rv-shadow--2 {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.08rem 0.026667rem -0.053333rem, rgba(0, 0, 0, 0.14) 0 0.053333rem 0.053333rem 0, rgba(0, 0, 0, 0.12) 0 0.026667rem 0.133333rem 0;
}

.rv-shadow--3 {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0.08rem 0.08rem -0.053333rem, rgba(0, 0, 0, 0.14) 0 0.08rem 0.106667rem 0, rgba(0, 0, 0, 0.12) 0 0.026667rem 0.213333rem 0;
}

.rv-icon--spin {
  -webkit-animation: rv-spin 1.5s infinite linear;
  animation: rv-spin 1.5s infinite linear;
}